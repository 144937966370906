import React, {useState, useEffect} from "react"
import {useThrottle} from "@react-hook/throttle"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTerminal, faBars, faHome, faNewspaper, faBriefcase, faBlog, faInfo, faEnvelope, faFilePdf} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub} from "@fortawesome/free-brands-svg-icons"
import {Icon} from "./styles"
import LinkItem from "./elements/linkItem"
import Languages from "./elements/languages"
import { dark, light, highlight } from "./styles"
import Image from "./image"
import {Link} from "gatsby"

const icons = [faHome, faEnvelope, faTerminal, faInfo, faNewspaper, faBriefcase, faBlog, faFilePdf, faLinkedin, faGithub]

type Props = {
  pageTitle: string,
}

const StyledHeader = styled.header`
  background-color: ${dark};
  box-shadow: 0 -2px 3px ${props => props.bg};
  z-index: 20000;
  position: sticky;
  bottom: 0;
  @media (min-width: 900px) {
    top: 0;
  }
   @media (prefers-color-scheme: dark) {
     background-color: ${light};
  }
  
`
const HeaderContent = styled.div`
  display: grid;
  grid-template-rows: 80px;
  grid-template-columns: 2% 18% 62% 18% 2%;
  @media (min-width: 900px) {
    grid-template-columns: 2% 6% 52% 38% 2%;
    grid-template-rows: 80px;
  }
  align-items: center;
`
const HeaderLinkText = styled.div`
  font-weight: 600;
  font-size: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 15%;
  cursor: pointer;
  grid-column-start: 3;
  @media (min-width: 900px) {
    font-size: 2.3rem;
  } 
   color: ${light};
  : hover {
    color: ${highlight};
  };
  @media (prefers-color-scheme: dark) {
     color: ${dark};
      : hover {
      color: ${highlight};
      };
  }
 
`
const HeaderLinks = styled.div`
  display: none;
  @media (min-width: 900px) {
    justify-self: end;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(${icons.length}, 1fr);
    grid-gap: 10px;
  }
`
const HeaderMobileMenu = styled.div`
  justify-self: end;
  padding-right: 20%;
  @media (min-width: 899px) {
    display: none
  }
  height: auto;
`
const HeaderMobileLinks = styled.div`
  @media (max-width: 899px) {
    justify-items: center;
    display: grid;
    align-items: start;
    grid-template-columns: repeat(${icons.length / 2}, 1fr);
    grid-template-rows: auto auto;
    grid-row-gap: 10%;
  }
`
const MobileLinksContainer = styled.div`
  background-color: ${dark};
  @media (prefers-color-scheme: dark) {
     background-color: ${light};
  }
  display: ${props => props.menuToggled ? "grid" : "none"};
  justify-self: center;
  align-items: center;
  height: 20vh;
`
const NavImgContainer = styled(Link)`
  grid-column-start: 2;
  text-decoration: none;
  cursor: pointer;
  width: 60px;
`
const Header: React.FC<Props>= ({pageTitle}) => {

  const [menuToggled, setState] = useState<boolean>(false);

  return (<StyledHeader >
    <MobileLinksContainer menuToggled={menuToggled} >
      <HeaderMobileLinks>
        {icons.map((icon, index) => {
          return <LinkItem key={index} index={index} selectedLink={pageTitle.toLowerCase()} icon={icon}/>
        })}
      </HeaderMobileLinks>
      <Languages languages={["en", "jp"]}/>
    </MobileLinksContainer>
    <HeaderContent>
      <NavImgContainer to={"/"}>
        <Image name={"icon"} />
      </NavImgContainer>
      <HeaderLinkText aria-label="Title" onClick={() => {window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })}}>
        <span id="title" style={{height: "100%"}}>{pageTitle}</span>
      </HeaderLinkText>
      <HeaderLinks>
        {icons.map((icon, index) => {
          return <LinkItem index={index} key={index} selectedLink={pageTitle.toLowerCase()} icon={icon}/>
        })}
      </HeaderLinks>
      {!menuToggled && <Languages languages={["en", "jp"]}/>}
      <HeaderMobileMenu>
        <Icon selected={false} onClick={() => setState(!menuToggled)}><FontAwesomeIcon icon={faBars} aria-label="menu" color={menuToggled ? "#e64172" : highlight } size="lg"/></Icon>
      </HeaderMobileMenu>
    </HeaderContent>
  </StyledHeader>)
}

export default Header
