import {HANDLE_EDITOR_CHANGE} from "../actions/editor";
const initialState = {
  value: `---
info:
  name: Matthew Fisher
  occupation: Software Engineer
  specializations:
    mobileApplications:
      - React Native
      - Swift
    webFrontend:
      - React
      - Vue
      - Gatsby
    backend:
      - Firebase
      - PHP
      - Rails
  interests:
    - Food
    - Tech
    - Travel
    - Music
    - Languages
    - Linguistics`,
}

export default function reducer(state = initialState, action) {

  switch (action.type) {
    case HANDLE_EDITOR_CHANGE:
      return Object.assign({}, state, {
        value: action.value
      });
    default:
      return state

  }
};


