import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { handleLinkSelect, toggleTerminal, setPreviousLink, toggleLanguageMenu } from "../../state/actions/nav"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { Icon, dark, light } from "../styles"


type Props = {
  icon: any,
  index: number,
  selectedLink: string,
}

const LinkLabelText = styled.span`
  font-size: 1rem;
  font-weight: bolder;
  width: 10px;
  color: ${light};
  @media (prefers-color-scheme: dark) {
     color: ${dark};
  }
`

const LinkItem: React.FC<Props> = ({icon, index, selectedLink}) => {
  const dispatch = useDispatch()
  const iconName = icon.iconName === 'briefcase' ? 'portfolio' : icon.iconName === 'newspaper' ? 'news' : icon.iconName === 'envelope' ? 'contact' : icon.iconName === 'file-pdf' ? 'resume' : icon.iconName
  if(iconName !== "github" && iconName !== "linkedin" && iconName !== "terminal" && iconName !== "language") {
    return (
      <Link
        key={index}
        aria-label={iconName}
        to={iconName === 'home' ? "/" : "/" + iconName}
        style={{ textDecoration: "none"}}
        >
        <Icon selected={selectedLink === iconName} onClick={() => {
          dispatch(setPreviousLink(selectedLink))
          dispatch(handleLinkSelect(iconName))
        }}>
          <FontAwesomeIcon icon={icon} style={{ justifySelf: "middle"}} />
        </Icon>
        <LinkLabelText>{iconName}</LinkLabelText>
      </Link>
    )
  } else if (iconName !== "terminal" && iconName !== "language") {
    let url;
    switch (iconName) {
      case "github":
        url = "https://github.com/Karstagg"
        break;
      case "linkedin":
        url = "https://www.linkedin.com/in/matthew-r-fisher/"
        break;
    }
    return (
      <a
        key={index}
        aria-label={iconName}
        href={url}
        target="_blank"
        style={{ textDecoration: "none" }}
        >
        <Icon selected={false}>
          <FontAwesomeIcon icon={icon} style={{ justifySelf: "middle" }}/>
        </Icon>
        <LinkLabelText>{iconName}</LinkLabelText>
      </a>
    )
  } else if(iconName !== 'terminal') {
    const languageMenuActive = useSelector(state => state.nav.languageMenuActive)
    return (
      <div
        key={index}
        aria-label={iconName}
        style={{ textDecoration: "none", cursor: "pointer"}}
        >
        <Icon selected={languageMenuActive} onClick={() => {
          dispatch(toggleLanguageMenu())
        }}>
          <FontAwesomeIcon icon={icon} style={{ justifySelf: "middle" }}/>
        </Icon>
        <LinkLabelText>{iconName}</LinkLabelText>
      </div>
    )
  } else {
    const modalActive = useSelector(state => state.nav.modalActive)
    return (
      <div
        key={index}
        aria-label={iconName}
        style={{ textDecoration: "none", cursor: "pointer"}}
        onClick={() => dispatch(toggleTerminal())}
        >
        <Icon selected={modalActive} >
          <FontAwesomeIcon icon={icon} style={{ justifySelf: "middle" }}/>
        </Icon>
        <LinkLabelText>{iconName}</LinkLabelText>
      </div>
    )
  }
}


export default LinkItem
