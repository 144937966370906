import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowAltCircleDown} from '@fortawesome/free-solid-svg-icons'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import { light, dark } from "../styles"
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from "gatsby"

type Props = {
  title: string,
  description: string,
}

const SplashContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-row: 1 /span 2;
  grid-template-rows: 30vh 25vh 45vh;
  grid-template-columns: 100%;
  text-shadow: 1px 1px 1px ${dark};
  @media (min-width: 900px) {
    grid-template-rows: 40vh 25vh 35vh;
    grid-template-columns: 60% 40%;
  }
  align-items: center;
  justify-items: center;
  color: ${light};
`
const SplashTitle = styled.h1`
  color: ${light};
  text-align: center;
  @media (min-width: 1100px) {
    font-size: 3.5rem;
    justify-self: end;
  }
  @media (max-width: 1099px) {
    grid-row-start: 1;
    font-size: 2.6rem;
  }
  align-self: end;
`
const SplashContent = styled.h2`
  color: ${light};
  text-align: center;
  @media (min-width: 1100px) {
    font-size: 2rem;
    grid-column-end: span 2;
  }
  @media (max-width: 1100px) {
    grid-row-start: 2;
    grid-column-end: span 2;
    font-size: 1.2rem;
  }
  justify-self: center;
  align-self: start;
`
const SplashButton = styled.button`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  align-self: start;
  text-align: center;
  justify-self: center;
  grid-column-end: span 2;
  border: 0px solid transparent;
  background-color: transparent;
  font-size: 9rem;
  @media (max-width: 900px) {
    grid-row-start: 3;
    font-size: 7rem;
  }
`
const Splash: React.FC<Props> = ({title, description}) => {
  const data = useStaticQuery(graphql`
        query {
               splashLight: file(relativePath: { eq: "images/splashLight.jpeg" }) {
                  childImageSharp {
                      fluid(quality: 80, maxWidth: 2000) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
        }
    `)

  return <BackgroundImage
    Tag={`section`}
    id={`splashBg`}
    fluid={data.splashLight.childImageSharp.fluid}
  >
    <SplashContainer>
      <SplashTitle>
        Welcome to {title}
      </SplashTitle>
      <SplashContent>
        {description}
      </SplashContent>
      <SplashButton>
        <AnchorLink to={'/#info'} className="anchorLink" stripHash={true}>
          <FontAwesomeIcon icon={faArrowAltCircleDown} style={{ justifySelf: "middle"}} />
        </AnchorLink>
      </SplashButton>
    </SplashContainer>
  </BackgroundImage>
}

const StyledSplash = styled(Splash)`
  width: 100%;
  min-height: 100vh;
`

export default StyledSplash
