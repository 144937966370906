import React, {useRef} from "react"
import {isMobile} from 'react-device-detect'
import styled from "styled-components"
import "../layout.css"
import { useSelector, useDispatch } from "react-redux"
import { toggleTerminal, handleLinkSelect } from "../../state/actions/nav"
import Terminal from 'react-console-emulator'
import { graphql, navigate, useStaticQuery } from "gatsby"

type Props = {
  location: any
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 1%;
  width: 98%;
  height: 75%;
  z-index: 100000;

  @media (min-width: 900px) {
   top: 7vh;
   left: 1.5vw;
    width: 96vw;
    height: 85vh;
  }
  display: ${props => props.modalActive ? "grid" : "none"}; 
  align-items: center;
  justify-items: center;
`
const ModalContent = styled.div`
  width: 100%;
  height: 80%;
  
  @media (min-width: 900px) {
    width: 100%;
  }
`
interface PathEdge {
  node: {
    path: string
  }
}
interface PathHash {
  [key: string]: string;
}


const TerminalModal: React.FC<Props> = ({ location }) => {
  const termRef: any = useRef(null);
  const modalActive: boolean = useSelector(state => state.nav.modalActive)
  const selectedLink = location && location
  const previousLink = useSelector( state => state.nav.previousLink)
  const dispatch = useDispatch()
  const data: object = useStaticQuery(graphql`
      query pathQuery {
          allSitePage {
              edges {
                  node {
                      path
                  }
              }
          }
      }
  `)

  if(modalActive) {
    termRef.current.focusTerminal()
  }

  //todo see if you can get graphql to return a hashmap...
  const edges = data.allSitePage.edges
  const pathHash = edges.reduce((map: PathHash, obj: PathEdge) => {
    const path = obj.node.path.replace(/[/]$/, "")
    map[path] = path
    return map
  }, {})
  const commands = {
    exit: {
      description: 'Close the Terminal',
      usage: 'Type "exit" to close the terminal',
      fn: () => {
        dispatch(toggleTerminal())
      },
      explicitExec: false,
    },
    Exit: {
      description: 'Close the Terminal',
      usage: 'Type "exit" to close the terminal',
      fn: () => {
        dispatch(toggleTerminal())
      },
      explicitExec: false,
    },
    ls: {
      description: 'ls',
      usage: 'Type "ls" to see what\'s around',
      fn: () => {
        let routeList: string = ""
        if(selectedLink === 'home') {
          for (let edge in edges) {
            const path = edges[edge].node.path;
            if (path !== "/dev-404-page/" && path !== "/offline-plugin-app-shell-fallback/") {
              if(path.split("/")[2] === "") {
                routeList += path.replace(/[/]/g, "") + " "
              }
            }
          }
          routeList += "linked-in" + " "
          routeList += "github" + " "
        } else {
          for (let edge in edges) {
            const path = edges[edge].node.path
            const dirInfo = path.split("/")
            if (dirInfo[1] === selectedLink && dirInfo[2]) {
              routeList += dirInfo[2] + " "
            }
          }
        }
        return routeList
      },
      explicitExec: false,
    },
    Ls: {
      description: 'ls',
      usage: 'Type "ls" to see what\'s around',
      fn: () => {
        let routeList: string = ""
        if(selectedLink === 'home') {
          for (let edge in edges) {
            const path = edges[edge].node.path;
            if (path !== "/dev-404-page/" && path !== "/offline-plugin-app-shell-fallback/") {
              if(path.split("/")[2] === "") {
                routeList += path.replace(/[/]/g, "") + " "
              }
            }
          }
          routeList += "linked-in" + " "
          routeList += "github" + " "
        } else {
          for (let edge in edges) {
            const path = edges[edge].node.path
            const dirInfo = path.split("/")
            if (dirInfo[1] === selectedLink && dirInfo[2]) {
              routeList += dirInfo[2] + " "
            }
          }
        }
        return routeList
      },
      explicitExec: false,
    },
    cd: {
      description: 'Navigate',
      usage: 'Type cd pagename to navigate',
      fn: (route: string) => {
        if(route) {
          const lcRoute = route.toLowerCase().replace(/[^a-z.~/-]/gi, "")
          if (lcRoute === 'home' || lcRoute === 'root' || lcRoute === '~') {
            dispatch(handleLinkSelect('home'))
            navigate('/')
          }
          if(lcRoute === 'linkedin') {
            window.location.href = "https://www.linkedin.com/in/matthew-r-fisher/"
          }
          if(lcRoute === 'github') {
            window.location.href = "https://github.com/Karstagg"
          }
          if(lcRoute === '..') {
            dispatch(handleLinkSelect(previousLink))
            window.history.back()
          }
          if (pathHash["/" + lcRoute]) {
            dispatch(handleLinkSelect(lcRoute))
            navigate(pathHash["/" + lcRoute])
          }
          if (pathHash["/"+ location +"/" + lcRoute]) {
            navigate(pathHash["/"+ location +"/" + lcRoute])
          }
        }

      },
      explicitExec: false,
    },
    Cd: {
      description: 'Navigate',
      usage: 'Type cd pagename to navigate',
      fn: (route: string) => {
        if(route) {
          const lcRoute = route.toLowerCase().replace(/[^a-z.~/-]/gi, "")
          if (lcRoute === 'home' || lcRoute === 'root' || lcRoute === '~') {
            dispatch(handleLinkSelect('home'))
            navigate('/')
          }
          if(lcRoute === 'linkedin') {
            window.location.href = "https://www.linkedin.com/in/matthew-r-fisher/"
          }
          if(lcRoute === 'github') {
            window.location.href = "https://github.com/Karstagg"
          }
          if(lcRoute === '..') {
            dispatch(handleLinkSelect(previousLink))
            window.history.back()
          }
          if (pathHash["/" + lcRoute]) {
            dispatch(handleLinkSelect(lcRoute))
            navigate(pathHash["/" + lcRoute])
          }
          if (pathHash["/"+ location +"/" + lcRoute]) {
            navigate(pathHash["/"+ location +"/" + lcRoute])
          }
        }

      },
      explicitExec: false,
    },
  }
  return (
    <ModalWrapper modalActive={modalActive}>
      <ModalContent>
        <Terminal style={terminalStyle}
                  promptLabelStyle={{color: "#e64172", fontSize: "1.5em"}}
                  welcomeMessage={isMobile ? `Welcome: type "help" for commands` :
                     `Welcome: This is a Unix Terminal emulator that supports basic commands for navigating this site. 
                     to see a list of available commands, please enter "help".
                  `}
                  inputStyle={{color: "#ffffff", marginTop: "5px", fontSize: "1.2em", textTransform: "lowercase"}}
                  commands={commands}
                  ref={termRef}
                  promptLabel={selectedLink ? selectedLink + ":$" : "home" + ":$"}
        />
      </ModalContent>
    </ModalWrapper>
  );
};

const terminalStyle = {
  height: '100%',
  background: "rgba(39, 40, 35, 0.9)",
  borderRadius: "20px",
  border: "1px solid #ffffff",
}
export default TerminalModal
