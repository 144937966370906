import {HANDLE_LINK_SELECT, TOGGLE_TERMINAL, SET_PREVIOUS_LINK, TOGGLE_LANGUAGE_MENU} from "../actions/nav";
const initialState = {
  selectedLink: 'home',
  previousLink: 'home',
  modalActive: false,
  languageMenuActive: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_LINK_SELECT:
      return Object.assign({}, state, {
        selectedLink: action.value
      });
    case SET_PREVIOUS_LINK:
      return Object.assign({}, state, {
        previousLink: action.value
      })
    case TOGGLE_TERMINAL:
      return Object.assign({}, state, {
        modalActive: !state.modalActive
      })
    case TOGGLE_LANGUAGE_MENU:
      return Object.assign({}, state, {
        languageMenuActive: !state.languageMenuActive
      })
    default:
      return state
  }
};


