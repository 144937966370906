import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

type Props = {
  shape?: String
  name: String
}

const Image: React.FC<Props> = ({shape, name}) => {
  const data = useStaticQuery(graphql`
    query {
      profile: file(relativePath: { eq: "images/profile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mikan: file(relativePath: { eq: "images/mikan.png" }) {
          childImageSharp {
              fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
              }
          }
      },
      manda: file(relativePath: { eq: "images/manda.png" }) {
          childImageSharp {
              fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
              }
          }
      },
     cats: file(relativePath: { eq: "images/cats.png" }) {
          childImageSharp {
              fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
              }
          }
      },
      code: file(relativePath: { eq: "images/code.png" }) {
          childImageSharp {
              fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
              }
          }
      },
      main: file(relativePath: { eq: "images/main.png" }) {
          childImageSharp {
              fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
              }
          }
      },
      icon: file(relativePath: { eq: "images/icon.png"})  {
          childImageSharp {
              fluid(maxWidth: 100) {
                  ...GatsbyImageSharpFluid
              }
          }
      }
      
    }
  `)

  return <Img fluid={data[name].childImageSharp.fluid} style={style[shape]}/>
}

const style = {
  circle: {
    borderRadius: "50%"
  },
  triangle: {

  },
  square: {
    maxHeight: "500px",
    borderRadius: "20px",
  },
  main: {
    maxHeight: "650px",
    borderRadius: "20px",
  }
}

export default Image
