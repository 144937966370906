import styled from "styled-components"


export const dark = '#272823'
export const light  = '#f2f3f4'
export const highlight = '#e64172'
export const highlightLight = 'rgba(230, 65, 114, 0.5)'
export const darkLight = 'rgba(39, 40, 35, 0.3)'
// @ts-ignore

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 10% 80% 10%;
`
export const CardsContainer = styled.div`
  display: grid;
  height: auto;
  width: 100%;
  grid-row-gap: 5%;
  grid-column-start: 2;
  grid-template-rows: repeat(${props => props.rows}, 300px);
  margin-bottom: 100px;
`
export const Icon = styled.div(({selected}) => {
  return `
  font-size: 1.8rem;
  color: ${selected ? highlight: light};
  @media (prefers-color-scheme: dark) {
     color: ${selected ? highlight: dark};
  }
  text-align: center;
  :hover {
    color: #e64172;
  }
`
})

export const LinkButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 10px;
  grid-column: 2;
  justify-items: center;
  align-items: center;
  @media (min-width: 769px) {
    grid-column: 2 / span 3;
    grid-template-columns: 50% 50%;
  }
  margin-bottom: 20px;
`

export const LinkButton = styled.a`
  text-decoration: none;
  display: grid;
  justify-items: center;
  align-items: center;
  color: ${light};
  
  width: 70%;
  height: 50px;
  @media (min-width: 769px) {
    height: 100px;
  }
  border-radius: 28px;
  cursor: ${props => props.href ? "pointer" : "not-allowed"};
  font-size: 1.5rem;
  font-weight: bolder;
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
    :active {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.45);
    }
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
    color: ${light};
    :active {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.1);
    }
  }
  background: ${props => !props.href ? "linear-gradient(135deg, rgba(230, 65, 114, 0.4), rgba(230, 65, 114, 0.5))" : "linear-gradient(135deg, rgba(230, 65, 114, 0.7), rgba(230, 65, 114, 1))"};
`

export const StyledButton = styled.button`
 text-decoration: none;
  display: grid;
  justify-items: center;
  align-items: center;
  color: ${light};
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
    :active {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.45);
    }
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
    color: ${light};
    :active {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.1);
    }
  }
  background: ${props => !props.enabled ? "linear-gradient(135deg, rgba(230, 65, 114, 0.4), rgba(230, 65, 114, 0.5))" : "linear-gradient(135deg, rgba(230, 65, 114, 0.7), rgba(230, 65, 114, 1))"};
  width: 70%;
  height: 50px;
  @media (min-width: 769px) {
    height: 100px;
  }
  border-radius: 20px;
  cursor: ${props => props.enabled ? "pointer" : "not-allowed"};
  font-size: 1.5rem;
  font-weight: bolder;
`

export const HeaderImg = styled.img`
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  @media(max-width: 560px) {
    object-fit: contain;
  }
`
export const ContentContainer = styled.div`
  display: grid;
  height: 100%;
  grid-row-gap: 2%;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 20vh auto auto;
  margin-top: 5vh;
  margin-bottom: 10vh;
  color: ${light};
`

