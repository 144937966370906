import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './src/state/createStore';
import TerminalModal from "./src/components/elements/terminalModal"

export const store = configureStore();

export default ({ element }) => (
  <Provider store={store}>
    <TerminalModal/>
    {element}
  </Provider>
);
