import {combineReducers} from 'redux';
import editor from './editor'
import nav from './nav'

const combinedReducer = combineReducers({
 editor,
 nav
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default rootReducer
