export const HANDLE_LINK_SELECT = 'LINK_SELECT';
export const TOGGLE_TERMINAL = 'TOGGLE_TERMINAL';
export const SET_PREVIOUS_LINK = 'SET_PREVIOUS_LINK'
export const TOGGLE_LANGUAGE_MENU = 'TOGGLE_LANGUAGE_MENU'

export const handleLinkSelect = value => {
  return {type: HANDLE_LINK_SELECT, value}
}

export const setPreviousLink = value => {
  return {type: SET_PREVIOUS_LINK, value}
}

export const toggleTerminal = () =>  {
  return {type: TOGGLE_TERMINAL}
}

export const toggleLanguageMenu = () => {
  return {type: TOGGLE_LANGUAGE_MENU}
}

