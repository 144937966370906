import React from "react"
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux"

type Props = {
  languages: Array<string>
}
const ListContainer = styled.div`
  width: auto;
  justify-self: end;
  grid-column-start: 3;
  color: #e64172;
  height: auto;
  grid-template-rows: repeat(20px);
`
const languages: React.FC<Props> = (languages) => {
  const languageMenuActive = useSelector(state => state.nav.languageMenuActive)
  const dispatch = useDispatch()
  return <>
    <ListContainer style={!languageMenuActive ? {display: 'none'} : {display: 'grid'}}>
      <ul>
        <li>English</li>
        <li>日本語</li>
      </ul>
    </ListContainer>
  </>
}



export default languages
