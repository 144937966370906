import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"
import { handleLinkSelect, setPreviousLink } from "../state/actions/nav"
import { useDispatch, useSelector } from "react-redux"
import {light} from "./styles"

const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: 100vw;
  justify-items: center;
  align-items: center;
  padding: 70px 0 20px 0;
  
`
const FooterLink = styled(Link)`
  text-decoration: none;
  font-size: 1.5rem;
  color: #e64172;
  transition: 150ms;
  : hover {
    color: #272823;
    font-size: 1.8rem;
  }
  @media (prefers-color-scheme: dark) {
    : hover {
    color: ${light};
    }
  }
`


const Footer: React.FC= () => {
  const selectedLink = useSelector(state => state.nav.selectedLink)
  const dispatch = useDispatch()
 return <StyledFooter>
      <FooterLink to={'/contact'} style={{textDecoration: 'none'}} onClick={() => {
        dispatch(setPreviousLink(selectedLink))
        dispatch(handleLinkSelect('contact'))
      }}>
        Contact
      </FooterLink>
  </StyledFooter>
}

export default Footer
