
import React from "react"
import {isMobile} from 'react-device-detect'
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Header from "./header"
import Footer from "./footer"
import TerminalModal from '../components/elements/terminalModal'
import Splash from "./splash/splash"


import "./layout.css"

type Props = {
  children: JSX.Element[],
  title?: string,
  location: any,
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 100%;
`

const Layout: React.FC<Props> = ({ children, location }) => {
  const currentPage: string = location ? location.pathname !== "/" ? location.pathname.split("/")[1] : "home" : "home"
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title,
                  description
              }
          }
      }
  `)

  const pageTitle: string = currentPage ? currentPage.replace(currentPage.charAt(0), currentPage.charAt(0).toUpperCase()) : data.site.siteMetadata.title
  const pageStyle: object = currentPage === 'home' ? styles.homeRows : styles.rows

  return (
    !isMobile ? <>
      <TerminalModal location={currentPage}/>
      <Wrapper style={pageStyle}>
        {location && location.pathname === "/" && <Splash title={data.site.siteMetadata.title} description={data.site.siteMetadata.description}/>}
        <Header pageTitle={pageTitle} />
        <main>
          {children}
        </main>
        {currentPage !== "resume" && <Footer/>}
      </Wrapper>
    </> : <>
      <TerminalModal location={currentPage}/>
      <Wrapper style={pageStyle}>
        <main>
          {children}
        </main>
        {currentPage !== "resume" && <Footer/>}
        <Header pageTitle={pageTitle} />
      </Wrapper>
    </>
  )
}

const styles = {
  rows: {
    gridTemplateRows: isMobile ? `1fr auto auto` : `auto 1fr auto`
  },
  homeRows: {
    gridTemplateRows: isMobile ? `1fr auto auto auto` : `auto auto 1fr auto`
  }
}

export default Layout
